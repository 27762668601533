<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h2 class="modal-title">¡Enhorabuena! Ya has completado el quiz</h2>
              </div>
              <div class="modal-body">
                <p>Felicidades, eres todo un Logi-experto. Síguenos en LinkedIn para estar informado de todas nuestras novedades</p>
                <div class="d-flex flex-row justify-content-center">
                  <button class="me-2 white"><router-link style="color: #26A361; text-decoration: none" to="historia">Ver Story</router-link></button>
                  <button class="ms-2"><a href="https://es.linkedin.com/company/logifruit" target="_blank" style="color: #fff; text-decoration: none" to="historia">Ir a Linkedin</a></button>

                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
  export default {
    name: 'ModalQuiz',
    props: ['modalStatus', 'modalContent'],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
   .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-dialog {
      	max-width: 700px;
    }

	.modal-header {
		border-bottom: none;
	}

    h2 {
		width: 100%;
		text-align: center;
		font-size: 50px;
		line-height: 1.14;
		font-weight: 600;
    }

	.modal-body {
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 0;
	}

	button {
		margin-top: 30px;
		margin-bottom: 30px;
		background: #26A361;
		border-radius: 30px;
		border: 1px solid #26A361;
		padding: 5px 22px;
		color: #fff
	}

  button.white {
		background: #fff;
		color: #26A361;
	}

  @media (max-width: 600px) { 
    .modal-body {
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      font-size: 28px;
    }
  }

</style>