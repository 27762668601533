<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h2 v-if="modalStatus === 'true'" class="modal-title">¡Genial! Es correcto</h2>
                <h2 v-else class="modal-title">¡Ups! No es correcto</h2>
              </div>
              <div class="modal-body">
                <p>{{ modalContent }}</p>
                <button @click="close">Aceptar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
  export default {
    name: 'ModalQuiz',
    props: ['modalStatus', 'modalContent'],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
   .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-dialog {
      	max-width: 700px;
    }

	.modal-header {
		border-bottom: none;
    padding-top: 40px;
	}

    h2 {
		width: 100%;
		text-align: center;
		font-size: 50px;
		line-height: 1.14;
		font-weight: 600;
    }

	.modal-body {
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 0px;
	}

	button {
		margin-top: 10px;
		margin-bottom: 30px;
		background: #26A361;
		border-radius: 30px;
		border: none;
		padding: 5px 22px;
		color: #fff
	}

  @media (max-width: 600px) { 
    .modal-body {
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      font-size: 28px;
    }
  }

</style>