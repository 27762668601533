<template>
  <div class="quiz">
	  <ModalQuiz v-if="modalVisible" :modal-status="isRight" :modal-content="questions[current_question].justification" @close="closeModal"/>
      <ModalFinish v-if="finishQuizModal" />
	  	<div class="container">
		  	<div class="row">
                <div class="col-12 text-center">
                    <router-link to="/">
                        <img class="logo" src="@/assets/img/logo-aniversario-green.svg">
                    </router-link>
                </div>
				<div class="col-md-2 d-none d-sm-flex justify-content-center align-items-center">
                    <ul class="question-index mt-5">
                        <li v-for="question in questions" v-bind:key="question" :class="[{'answered': parseInt(questions.indexOf(question)) < current_question + 1}, {'current': questions.indexOf(question) == current_question }] "><p>{{ questions.indexOf(question) + 1 }}</p></li>
                    </ul>
                </div>
                <div class="col-md-8 col-12">
                    <p class="question-number">Pregunta {{ questions.indexOf(questions[current_question]) + 1 }}</p>
                    <h2 class="question-title">{{ questions[current_question].question_title }}</h2>
					<img :style="{width: questions[current_question].question_image.size + '%'}" class="question-img" :src="questions[current_question].question_image.url">
                </div> 
				<div class="col-md-2 d-none d-sm-flex"></div>
            </div>
		</div>
		<section class="options">
			<div class="container">
				<div class="row">
					<div class="col-2 d-none d-sm-flex"></div>
					<div class="col-md-8 col-12">
						<div class="row">
							<div class="h-100" :class="[{'col-md-6': questions[current_question].options.length<3},{'col-md-4': questions[current_question].options.length<4}]" v-for="option in questions[current_question].options" v-bind:key="option">							
								<button @click="showModal(option.value)" class="option" :class="[option.value, { 'small': option.explanation.length>10}]">{{ option.explanation}}</button>
							</div>
						</div>
					</div>
					<div class="col-md-2 col-12 pt-md-5 d-flex justify-content-between d-md-inline-block">
						<button v-on:click="prevQuestion()"><img class="arrow" src="@/assets/img/right-arrow-green.svg"></button>
						<button v-on:click="nextQuestion()"><img class="arrow" src="@/assets/img/right-arrow-green.svg"></button>
					</div>
				</div>
			</div>
	 	</section>
  </div>
</template>

<script>
import ModalQuiz from '@/components/ModalQuiz.vue';
import ModalFinish from '@/components/ModalFinish.vue';

export default {
    name: 'Quiz',
	components: {
      	ModalQuiz,
        ModalFinish
    },
    data() {
        return {
			isRight: 'false',
			modalVisible: false,
            finishQuizModal: false,
            current_question: 0,
            questions: [
                {
                    number: 1,
                    question_title: '¿Cuanto plástico de envases Logifruit se recicla y se reutiliza para producir nuevos envases?',
                    question_image: {
                        url: require('../assets/img/quiz/1.gif'),
                        size: '100',
                    },
                    options: [
                        {
                            explanation: '78%',
                            value: 'false',
                        },
                        {
                            explanation: '85%',
                            value: 'false',
                        },
                        {
                            explanation: '100%',
                            value: 'true',
                        }
                    ] ,
                    justification: 'Todos nuestros envases son modulares, facilitando su reparación y alargando así su vida útil. Una vez esto ya no es posible, los envases o las partes de envases que no vamos a volver a utilizar se trituran y se reciclan, reinyectando el 100% de este plástico de nuevo en el proceso de producción de nuestros envases. Conseguimos de esta manera que nuestros envases generen un Residuo Cero.'
                },
                {
                    number: 2,
                    question_title: '¿En qué consiste la innovacion abierta?',
                    question_image: {
                        url: require('../assets/img/quiz/2.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: 'Publicar un infore anual con todos los proyectos de innovación',
                            value: 'false',
                        },
                        {
                            explanation: 'En la cooperación entre empresas y start-ups y centros de innovación para llevar a cabo proyectos de innovacion',
                            value: 'true',
                        }
                    ] ,
                    justification: 'En Logifruit contamos con un observatorio de innovación abierta, en el cual y gracias a la colaboración con centros de conocimiento y aceleradoras de startups, realizamos un mapeo constante de soluciones innovadoras y estudiamos junto a las empresas tecnológicas la posibilidad de aplicarlas para mejorar nuestros procesos, con el objetivo de conseguir una red de suministro digital, conectada y sostenible.'
                },
                {
                    number: 3,
                    question_title: '¿Cuáles son las 3R propias que en Logifruit le hemos añadido a las 3R de la Economía circular? ',
                    question_image: {
                        url: require('../assets/img/quiz/3.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: 'Racionalizar, Rediseñar, Reparar',
                            value: 'true',
                        },
                        {
                            explanation: 'Repensar, Rediseñar, Recomprar',
                            value: 'false',
                        },
                        {
                            explanation: 'Racionalizar, Restar, Recuperar',
                            value: 'false',
                        }
                    ] ,
                    justification: 'En Logifruit trabajamoscon un sistema 6R, una apmpliacion de las 3R tradicionales de la economía circular a las que hemos añadido 3 R propias:  Racionalizar: compromiso de reducir el impacto de cada uno de nuestros procesos, con la finalidad de hacer más con menos.'
                },
                {
                    number: 4,
                    question_title: '¿En que año pusimos en marcha por primera vez el Plan de Igualdad? ',
                    question_image: {
                        url: require('../assets/img/quiz/4.gif'),
                        size: '70',
                    },
                    options: [
                        {
                            explanation: '2010',
                            value: 'false',
                        },
                        {
                            explanation: '2012',
                            value: 'true',
                        },
                        {
                            explanation: '2015',
                            value: 'false',
                        }
                    ] ,
                    justification: 'La igualdad es un principio estratégico deLogifruit y por ello lanzamos en  2012, nuestro primer Plan de Igualdad, que hemos actualizado con el lanzamiento del  II Plan de Igualdad de Oportunidades en 2018, integrando las políticas que aseguran la igualdad de trato y de oportunidades. Con este plan fomentamos el crecimiento tanto personal como profesional de todas las personas que forman parte de la plantilla invirtiendo en formación y creando planes de carrera con el fin de asegurar la igualdad efectiva entre todos los colaboradores y colaboradoras. '
                },
                {
                    number: 5,
                    question_title: '¿En que año nos sumamos al Pacto Mundial? ',
                    question_image: {
                        url: require('../assets/img/quiz/5.gif'),
                        size: '70',
                    },
                    options: [
                        {
                            explanation: '2011',
                            value: 'true',
                        },
                        {
                            explanation: '2014',
                            value: 'false',
                        },
                        {
                            explanation: '2016',
                            value: 'false',
                        }
                    ] ,
                    justification: 'En Logifruit llevamos desde 2011 adheridos al Pacto Mundial de la ONU, con el correspondiente compromiso con sus 10 Principios. Y desde 2015 trabajamos para contribuir el cumplimiento de los Objetivos de Desarrollo Sostenible, convencidos de que solo las empresas realmente sostenibles y resilientes tendrán cabida en el futuro. Durante 2020 actualizamos los ODS a los que nos sumamos, como parte de nuestra estrategia RSC.'
                },
                {
                    number: 6,
                    question_title: '¿Cuánto espacio ocupa una caja plegable frente a una rígida? ',
                    question_image: {
                        url: require('../assets/img/quiz/6.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: 'La mitad',
                            value: 'false',
                        },
                        {
                            explanation: 'Un tercio',
                            value: 'true',
                        }
                    ] ,
                    justification: 'Gracias al ecodiseño sustituimos todo nuestro parque de cajas rígidas por cajas plegables en 2019. Este cambio supuso grandes ahorros de espacio en el transporte de las cajas en lso camiones, donde antes se transportaba un envase ahora caben tres. Gracias a esto y a las eficiencias en el transporte, conseguimos un ahorro de CO2 de 767 toneladas en 2020.'
                },
                {
                    number: 7,
                    question_title: '¿Cuántas estrellas Lean & Green tenermos? ',
                    question_image: {
                        url: require('../assets/img/quiz/7.gif'),
                        size: '75',
                    },
                    options: [
                        {
                            explanation: '1',
                            value: 'false',
                        },
                        {
                            explanation: '2',
                            value: 'true',
                        },
                        {
                            explanation: '3',
                            value: 'false',
                        }
                    ] ,
                    justification: 'Lean & Green es una iniciativa internacional cuyo objetivo es ayudar a las empresas de todos los sectores a reducir sus emisiones de gases de efecto invernadero derivados de las actividades logísticas para alcanzar los objetivos definidos en la Cumbre del Clima en París (COP21) de emisiones neutras en carbono para el año 2050.  Nacida en los Países Bajos, y gestionada en España por AECOC, Lean & Green cuenta actualmente con presencia en 14 países y más de 600 empresas adheridas en toda Europa, siendo una comunidad europea líder en logística sostenible.'
                },
                {
                    number: 8,
                    question_title: '¿En cuántas plataformas estamos presentes?',
                    question_image: {
                        url: require('../assets/img/quiz/8.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: '12',
                            value: 'false',
                        },
                        {
                            explanation: '13',
                            value: 'false',
                        },
                        {
                            explanation: '14',
                            value: 'true',
                        }
                    ] ,
                    justification: 'La red logística de Logifruit está estratégicamente distribuida por todo el territorio nacional facilitando la atención, el contacto directo y la cercanía con sus más de 1020 clientes. Con sede central en Valencia, la empresa dispone de 13 plataformas en España y 1 en Portugal con instalaciones altamente automatizadas y tecnología de última generación.'
                },
                {
                    number: 9,
                    question_title: '¿Qué porcentaje de la plantilla trabaja con contrato indefinido? ',
                    question_image: {
                        url: require('../assets/img/quiz/9.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: '76%',
                            value: 'false',
                        },
                        {
                            explanation: '79%',
                            value: 'false',
                        },
                        {
                            explanation: '83%',
                            value: 'true',
                        }
                    ] ,
                    justification: 'Trabajamos por crear empleo seguro y estable para toda la plantilla, con planes de formacion que les permita seguir promocionando y creciendo dentro de la empresa.'
                },
                {
                    number: 10,
                    question_title: '¿Cuántas toneladas de plastico se reciclaron en 2020?',
                    question_image: {
                        url: require('../assets/img/quiz/10.gif'),
                        size: '50',
                    },
                    options: [
                        {
                            explanation: '3456',
                            value: 'false',
                        },
                        {
                            explanation: '4041',
                            value: 'true',
                        },
                        {
                            explanation: '5596',
                            value: 'false',
                        }
                    ] ,
                    justification: 'Gracias al ecodiseño y a nuestro esfuerzo en el proceso de reparación conseguimos alargar la vida útil de nuestros envases.NO obstante, cuando ya no es posible reparar, el  100% del plástico de piezas con desperfectos se tritura, recicla y reinyecta en el proceso de producción de nuestros envases. Por ello, reciclamos en 2020 4041 toneladas de nuestros propios envases y conseguimos generar Residuo Cero. '
                },
                
            ]
        }
    },

    watch: {
        current_question: function() {
            if (this.current_question > 4) {
                this.finishQuizModal = true
            }
        }
    },

    created() {
        for(var i = 0; i < 5; i++){ 
            var randomElement = Math.floor(Math.random() * this.questions.length);
            this.questions.splice(randomElement, 1)
        } 

        for (let i = this.questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.questions[i], this.questions[j]] = [this.questions[j], this.questions[i]];
        }

        console.log(this.questions)
    },

	methods: {
		nextQuestion() {
			if (this.current_question < 4) {
				this.current_question += 1
                console.log(this.current_question)
			}

            else {
                this.finishQuizModal = true 
            }
		},

		prevQuestion() {
			if (this.current_question > 0) {
				this.current_question -= 1
			}
		},

		showModal(value) {
			this.isRight = value
			this.modalVisible = true;

		},
		closeModal() {
			this.modalVisible = false;
            if (this.current_question < 4) {
                this.nextQuestion();
            } else {
                 this.finishQuizModal = true
            }
		}
	}
}
</script>

<style scoped>
	html, body {
		color: #000
	}

	.quiz {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		min-height: 100vh;
		width: 100%;
	}

    .logo {
        margin-top: 20px;
        width: 120px
    }

    .question-index {
        list-style: none;
        padding: 0;
    }

    .question-img {
        max-height: 300px;
        object-fit: contain;
        object-position: top;
    }

    .question-index li {
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
        color: #26A361;
        border: 2px solid #26A361;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .question-index li p {
        font-size: 18px;
        text-align: center;
        margin: 0
    }

    .question-index li.answered {
        background-color: #26A361;
        color: #fff;
        width: 40px;
        height: 40px
    }

    .question-index li.current {
        width: 50px;
        height: 50px;
        background-color: #26A361;
        color: #fff
    }

     .question-index li.current p {
        font-size: 21px;
    }

	.question-number {
		margin-top: 40px;
		font-size: 20px;
		font-weight: 400;
	}

	h2 {
		font-size: 50px;
		line-height: 1.14;
		font-weight: 600;
	}

	.options {
		width: 100%;
		padding-bottom: 30px;
		background-color: #26A361;
	}

	.options .row {
		height: 150px;
	}

	.option {
		margin-top: -40px;
		margin-bottom: 40px;
		height: 100%;
		width: 100%;
		font-size: 45px;
		font-weight: 600;	
		padding: 25px;
		border:none;
		background: #fff;
		border: 8px solid #fff;
		box-shadow: 0px 5px 10px rgba(0,0,0,.2);
	}

	.option.small {
		font-size: 18px;
	}

	.option.false:focus, .option.false:active {
		background: #FD7404;
		border-color: #fd7404;
		color: #fff
	}

	.option.true:focus, .option.true:active {
		background: #26A361;
		border-color: #fff;
		color: #fff
	}

	button {
		background: #fff;
		border: none;
		padding: 10px;
	}

	button .arrow {
		width: 30px;
	}

	button:first-child {
		margin-right: 30px;
	}

	button:first-child .arrow {
		transform: rotate(180deg);
	}

    @media (max-width: 1000px) { 
        .options .row {
            height: 320px
        }

        h2 {
            font-size: 38px
        }

        button:first-child {
            margin-right: 10px;
        }

        img.arrow {
            width: 20px;
        }

        .option {
            font-size: 28px;
        }

        .option.small {
            font-size: 16px
        }
    }


    @media (max-width: 600px) { 
        h2 {
            font-size: 28px;
        }

        .question-img {
            margin-top: 20px;
            width: 100% !important
        }

        .options {
            width: 100%;
            padding-bottom: 20px;
            background-color: #26A361;
        }

        .options .row {
            height: auto;
        }

        .option {
            margin-top: -20px;
            margin-bottom: 40px;
            font-size: 21px;
            padding: 15px;
        }

        .option.small {
            font-size: 14px;
        }

        button:first-child {
            margin-right: 0;
        }

        .logo {
            margin-top: 20px;
            width: 80px
        }
    }

</style>